@import "../../styles/variables.scss";
.crude-bg {
  background-color: #fff;
  padding: 1rem;
  width: calc(100vw - 5rem);

  .btn{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0.5rem;

    &.btn-default{
      align-content: center;
      width: 9rem;
      height: 3rem;
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: 0.5rem;
      gap: 0.5rem;
      color: white;
      border: 1px solid #006bd3;
      cursor: pointer;
      background-color: #006bd3;
    }

    &.btn-primary,
    &.btn-secondary {
        display: flex;
        min-width: 6rem !important;
        width: 100% !important;
        max-width: 9rem !important;
        height: 3rem;
        padding: 0.5rem 1rem;
        justify-content: center !important;
        align-items: center !important;
        gap: 0.5rem;
        border-radius: 0.5rem;
        margin: 0.5rem 0rem;
    }
    &.btn-secondary {
        color: $primary-color;
        border: 1px solid var(--Brand-Ecolab-Blue, $primary-color);
    }
    &.btn-primary {
        color: $default-white;
        background: var(--Brand-Ecolab-Blue, $primary-color);
    }
  }
  .btn:disabled{
    opacity: 0.5;
    cursor: default;
  }

  .widthchange {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sharedDD-wrapper {
    .dd-integrated-container.select-integrated {

      &.disable{
        .dd-control-container {
          span[role=textbox]{
            background-color: #eee;
          }
        }
      }

      .dd-control-container {

        &.hidden {

          .dd-display-item {
            color: $primary-color;
          }
        }

        .dd-display-item {
          height: 3rem;
          padding: 0.5rem 1rem !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          line-height: 2rem;
          border: 1px solid $primary-color;

          span {
            display: block;
          }
        }

        .dd-display-item:after {
          border-top-color: $primary-blue;
        }

        &.shown {
          .dd-display-item:after {
            border-top-color: $primary-blue;
          }
        }
      }

      .dd-list-container {
        border-radius: 0.5rem;
        background-color: white;
        max-width: none;
        width: 100%;
        margin: 0rem auto;

        .dd-list-item {
          label.checkbox-items {
            .checkmark {
              border: 2px solid $primary-color !important;
            }
          }
        }

        .list-items {
          padding-bottom: 0.5rem;
          .dd-items-container {
            overflow-y: scroll;
            padding-top: 0.25rem;

            .tooltip.bs-tooltip-bottom {
              display: none !important;
            }
          }
        }
      }

      .dd-list-item label.checkbox-items {
        display: block;
        background-color: $primary-white;
      }
    }
  }

  .crude-hr{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    height: 1px !important;
  }

  .ml-0{
    margin-left: 0px !important;
  }
}

@media (max-width: 767.98px) {
  .crude-bg {
      width: 100vw;
      margin-left: 8px;
      padding: 1rem;

      .customerName {
        width: fit-content !important;
        max-width: 18rem !important;
      }

      .blending-data{
        padding: 1rem 0rem !important;
      }

      nalco-crude-flex{
        .crude-layout{
          .row{
            margin-left: unset !important;
          }
        }
      }

      nalco-feed-data{
        .card-body {
          padding: 1rem 0rem !important;

          .widget {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }

        .widthchange {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        .container {
          padding: 0;
        }

        .feed1 {
          margin: 1rem auto;
        }

        .chartPlot {
          padding: 1rem 0rem !important;
        }
      }

      .widthblending{
        .container{
          padding: 0;
        }
      }

      .nav-tabs {
        display: inline-block;
        overflow: auto;
        overflow-y: hidden;
        max-width: 100%;
        margin: 0 0 1em;
        white-space: nowrap;
        background: #AAA;
      }
    
      .nav-tabs li {
        display: inline-block;
        vertical-align: top;
      }

      mat-sidenav{
        .btn{
          max-width: 6rem !important;
          padding: 0.5rem !important;
        }
      }
      .crude-index-grid{
          width: 100vw;
          padding-left: 0;
          margin-left: 0;
          left: 0;
          position: absolute;
      }
      
  }
}

.crude-layout {

.crude-index-grid{
  padding: 1rem 0rem;
  height: calc(100vh - 16.5rem);
  min-height: 40rem;

  .displayFilter {
    .k-filter-row {
        background: #F5FAFD;

        td {
            background: #F5FAFD;
        }
    }
  }
  
  .hideFilter {
      .k-filter-row {
          display: none;
      }
  }

  .k-grid{
    max-height: unset !important;
  }
  kendo-grid{
    height: 100% !important;

    thead{
      background-color: #F5FAFD;
    }

    tbody tr:nth-child(even) {
      background-color: #F5FAFD !important;
    }
  
    tbody tr:nth-child(odd) {
      background-color: #ffffff !important; 
    }

    input {
      border: 1px solid #80B5E9;
      border-radius: 0.25rem; 
      font-size: 14px; 
      font-family: "roboto";
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      width: 100% !important;
      height: 2rem !important;
      z-index: auto;
    }

    .k-button{
      border: 1px solid #80B5E9;
      border-radius: 0.25rem; 
      color : #093675;
      background: none;
      height: 2rem;
    }

    .k-table-th{
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      font-weight: bold;
      color : #093675;
    }

    .crude-filter-icon {
      &.show-filter {
          background: url('/assets/images/fi-filter-blue.png') !important;
          width: 1.5rem !important;
          height: 1.5rem !important;
          cursor: pointer;
          margin: auto;
      }
  
      &.hide-filter {
          background: url('/assets/images/fi-filter-darkblue.png') !important;
          width: 1.5rem !important;
          height: 1.5rem !important;
          background-position: 0;
          background-repeat: no-repeat;
          background-size: contain;
          margin: auto;
      }
    }

    .filter-close-icon {
      background: url('/assets/images/close.png');
      width: 1.5rem !important;
      height: 1.5rem !important;
      background-position: 0;
      background-repeat: no-repeat;
      background-size: 0.7rem 0.7rem;
      background-position: center;
      padding: 0.25rem;
      margin: auto;
    }

  }
  .k-grid-content{
    overflow: scroll !important;
  }
  
}

.feed-header {
    color: #007ac9;
    margin: 16px 0px 0px 22px;
    font-weight: bold;
}
.siteAssign{
    max-width: fit-content;
    margin-left: auto;
    margin-top: 11px;
    margin-right: 10px;
}

@media (min-width: 1200px){
  .container {
      max-width: 5000px;
  }
}
@media (min-width: 768px){
  .side-panel{
    width: 40%;
  }
}
@media (max-width: 768px){
  .side-panel{
    width: 100%;
  }
}
.crude-main-page-top{
  padding-top: 50px !important;
  background-color: #fff;
}
.widthadjust {
    padding-top: 25px;
}

.widthblending {
    margin-top: -18px;
}
ngb-tabset {
    justify-content: center;
}
.crude-nav {
    ul{
      .nav-item{
        &:nth-child(1)
        {
          margin-left: 0;
        }
      }
    }
}
.crude-nav {
    width: 100%;
    display: block;
    &.mapListWrap{
      min-height: 200px;
      background: #fff;
    }
    ul{
      border-bottom: 1px solid #e2e2e2;
      margin: 0;
      .nav-item
      {
        padding: 0.25rem 1.00rem 0 #{pxToRem(8)};

        .nav-link{
          @include font-size(16);
          background: $primary-white;
          border: 0;
          color: cornflowerblue;
          &.active{            
            color: darkblue;
            border-bottom: 0.25rem solid $primary-gray;
            cursor: default;            
          }
          &:hover{
            color: $primary-gray;
          }
        }

        &:nth-child(1)
        {
          margin-left: #{pxToRem(7)};
        }

      }

      .nav li{
        @include font-size(24);
        line-height: 1.75rem;
        margin-right: 1rem;
        padding: 1rem 0rem;
        margin-left: 1rem;
      }
    }
  //legionella growth risk css starts
  &.crudeTransparent {
    ul{
      background: transparent;
      margin-bottom: 10px;
      &.nav-tabs{
        margin-left: 0px;
        border-bottom: 1px solid #D9EAF9;
      }
      &:last-child{
        margin-bottom: 0px;
      }
      .nav-item{
       &:first-child{
        margin-left: 0px;
        padding-left: 0px;
       }
        .nav-link{
          background: transparent;
          &.active{            
            border-bottom: 0.20rem solid darkblue;
            cursor: default;            
          }
        }
       
        &:last-child{
          border-right: none;
         }
      }

      @media screen and (max-width:550px) {
          .nav-item{
            &:last-child{
              margin-left: 0px;
              padding-left: 0px;
            }
          }
      }
    }
    .tab-content > .tab-pane {
      background:transparent;
    }
    .cleveland-tile{
      background-color: transparent;
      box-shadow: none;
    }
  }

  //legionella growth risk ends
  &.crude-sub-nav {
    ul.nav{
      background: #fff !important;
      margin-bottom: 0px;
      .nav-item{
        &:first-child{
         margin-left: 10px;
         padding-left: 10px;
        }
       }
    }
    .tab-content > .tab-pane {
      background: #fff !important;
    }
  }
 
}

.vertical-tabs .nav-pills {
  display: flex;
  flex-direction: column;
}

.active-tab{
  td {
    border-left: 4px solid  darkblue; /* Blue line on the left */
    a {
      color: darkblue; /* Blue text color */
    }
    a:hover {
      color: darkblue; /* Darker blue on hover */
    }
  }
}

/* Regular styling for the table cells */
.crude-table-bordered {
  td {
    cursor: pointer;
    padding-left: 10px; /* Adjust padding to align text properly */
    a {
      display: block;
      padding: 10px;
      text-decoration: none;
      color: #333;
    }
    a:hover {
      color: darkblue;
    }
  }
}

.crude-custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;

  th, td {
    border: 1px #D9EAF9;
    padding: 6px;
    width: 100%;
    padding: 10px;
    border-bottom: 2px solid #D9EAF9;
    line-height: 2rem !important;
  }

  td{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #093675;
    padding-right: 20px;
  }

  input {
    border: 1px solid #80B5E9;
    border-radius: 4px; 
    font-size: 14px; 
    font-family: "roboto";
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    width: 9rem !important;
    height: 2rem !important;
  }

  input::placeholder {
    color: #006BD3;
  }

  thead th {
    background-color: #F5FAFD;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: -0.01em;
    text-align: center;
    color : #093675;
    padding-right: 20px;
  }

  input {
    width: 100%;
    box-sizing: border-box;
  }

  tbody tr:nth-child(even) {
    background-color: #F5FAFD;
  }

  tbody tr:nth-child(odd) {
    background-color: #ffffff; 
  }
}

/* Container for the table with horizontal scrolling */
.table-container {
  position: relative;
  margin-top: 20px;
  overflow-x: auto;  
  white-space: nowrap;  
  -ms-overflow-style: none;  
  scrollbar-width: thin; 
}
.table-container::-webkit-scrollbar {
  height: 20px; 
}
.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;  
}



.pagination-container {
  position: absolute;
  right: 0; 
  bottom: -40px; 
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Arial, sans-serif;
}

.pagination-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

span {
  font-size: 14px;
}

.tab-title{
  margin: 0px 0px;
}

.table-actions-bar{
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.side-panel{
  color: #093675;
  top: 4rem !important;
  overflow: hidden !important;
  z-index: 9999;

  .mat-drawer-inner-container{
    overflow: hidden !important;
  }
}

.mat-drawer-container{
  background-color: unset;
  z-index: unset;
  .mat-drawer-content{
    z-index: unset;
  }
}

.sub-header{
  z-index: 1 !important;
}

.main-content{
  background-color: #fff;
}

.addCrude-button {
  width: 9rem;
  height: 3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-left: 1rem;
  gap: 0.5rem;
  color: white;
  border: 1px solid $primary-color;
  $border-light-blue: white;
  font-family: $base-font-family;
  cursor: pointer;
  font-size: $font14;
  line-height: $font20;
  font-weight: 500;
  background-color: $primary-color;

  .plus-icon {
    background: url('/assets/images/add.png');
    width: 14px;
    height: 14px;
    margin-top: 3px;
  }
}

.customize-button {
  width: 9rem;
  height: 3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  color: $primary-color;
  border: 1px solid $primary-color;
  $border-light-blue: white;
  font-family: $base-font-family;
  cursor: pointer;
  font-size: $font14;
  line-height: $font20;
  font-weight: 500;
  background-color: #fff;

  .customize-icon {
      background: url('/assets/images/Edit.png');
      width: 19px;
      height: 19px;
      margin-top: -0.2rem;
  }

}


.restore-customize,
.apply-customize {
    display: flex;
    width: 8.5rem;
    height: 3rem;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
}
.restore-customize {
    color: $primary-color;
    border: 1px solid var(--Brand-Ecolab-Blue, $primary-color);
}
.apply-customize {
    color: $default-white;
    background: var(--Brand-Ecolab-Blue, $primary-color);
}


.add-crude-btn {
  display: flex;
  width: 8.5rem;
  height: 3.25rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
}

.sidebar-main-content{
  padding: 1.5rem;
  height: calc(100vh - 16rem);
  overflow-y: auto;

  &.short-header{
    height: calc(100vh - 14rem);
  }

  &.no-pad{
    padding: 0 !important;
  }

  .row{
    margin: 0 !important;

    input, select{
      margin-bottom: 0.75rem;
      color: #006bd3;
    }
    input:disabled,
    select:disabled{
      opacity: 0.4 !important;
      pointer-events: none !important;
      background: #EEE;
    }

    label{
      margin-bottom: 0.25rem;
      display: flex;
      align-items: flex-end;
    }
  }
}

.content-brief{
  margin-left: 0px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  font-size: 14px;
  font-family: "roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

}

.sidebar-header{
  padding: 0px;
  margin: 0px;
  width: 100%;
  background-color: #ECF5FC;
  padding: 1.5rem;
  border-bottom: 1px solid #D9EAF9;
    p{
      margin-bottom: 0px;
    }
    
}
.sidebar-header-title{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "roboto";
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}

.sidebar-header-note{
  max-width: 500px;
  font-family: "roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.sidebar-footer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 0rem;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 5rem;
  background-color: #fff;
}


.sidebar-list {
  margin-top: 1rem;
  width: 100%;
  border: solid 1px #D9EAF9;
  display: block;
  background: #D9EAF9;
  border-radius: 0.5rem;
  overflow: hidden;
}

.list-item-box {
  padding: 1rem 0rem;
  border-bottom: solid 1px #D9EAF9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  cursor: move;
  background: #F5FAFD;
  font-size: 14px;
}

.list-item-label{
  padding: 0px;
  margin: 0px;
  height: 20px;
  vertical-align: middle;
}

.drag-indicator{
  background: url('../../assets/images/drag-indicator.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-left: 0;
  margin-right: 1rem;
  margin-left: 1rem;
  vertical-align: middle;
}

.item-label-text{
  display: inline-block;
  margin-left: 0;
  height: 20px;
  vertical-align: middle;
  margin-top: 2px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.list-item-box:last-child {
  border: none;
}

.sidebar-list.cdk-drop-list-dragging .list-item-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.side-panel{

  .list-item-label {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .list-item-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .custom-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #006BD3;
    border-radius: 3.33px;
    margin-right: 0.5rem;
  }
  
  /* On mouse-over, add a grey background color */
  .list-item-label:hover input ~ .custom-checkmark {
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .list-item-label input:checked ~ .custom-checkmark{
    background: none;
  }
  .list-item-box .list-item-label input:checked {
    background-color: #fff;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .custom-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .list-item-label input:checked ~ .custom-checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .list-item-label .custom-checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 9px;
    border: solid #006BD3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.link-style {
  color: #007ac9;
  text-decoration: underline;
}
}
