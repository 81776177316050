@import "../../../../../styles/variables.scss";

.inventoryTile {
  .k-grid td {
    text-transform: unset;
    white-space: normal;
    overflow: unset;
    border-color: $secondary-light-gray !important;
  }

  .k-grid-pager{
    border-color: $primary-white;
    background-color: $primary-white;
  }
  .k-grid tbody tr {
    background-color: $primary-white !important;
  }

  .k-grid-content-locked {
    overflow: unset;
    height: auto !important;
  }

  .k-grid-header-locked {
    border-width: 0;
  }

  .k-grid-content.k-virtual-content {
    overflow: auto !important;
  }

  .cell-padding {
    padding-top: 5px;
  }

  .tank-reading-content {
    display: inline-block;  
    width: 80px;
  }

  .tank-reading-pdf-content {
    display: inline-block;  
    width: 65px;
  }

  .showPDFDataSourceIcon {
    border-radius: 12px;
    color: $primary-white;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    background-color: $linear-gradient-starting-blue;
    position: relative;
    left: 10px;
  }

  .showDataSourceIcon {
    border-radius: 12px;
    color: $primary-white;
    width: 20px;
    height: 19px;
    display: inline-block;
    text-align: center;
    padding: 2px 0 0 0;
    top: 1px;
    position: relative;
    background-color: $linear-gradient-starting-blue;
    vertical-align: bottom;
    left: 5px;
  }

  .k-grid-header {
    width: 98%;
  }

  .ico-info-thin {
    padding-left: inherit;
  }

  .warningIcon {
    cursor: pointer;
    float: left;
    text-align: center;
    width: 100%;
  }

  .iconalert-red-print {
    position: relative;
    border-left: 0.80em solid transparent;
    border-right: 0.80em solid transparent;
    border-bottom: 1.50em solid $utilitarian-red;
    top: -1em;
    border-radius: 3px;
  }

  .iconalert-red-print::before {
    content: "\0021";
    top: 0.75em;
    left: -0.15em;
    position: absolute;
    color: white;
    font-size: 1.5em;
    line-height: 1em;
    font-weight: bold;
  }

  .icon-span {
    font-size: 14px;
  }

    .inventory-pdf-wrapper {
      .inventory-table-container {
        display: inline-block;
        width: 100%;
        vertical-align: top;
  
        .inventory-pdf-header-alignment {
          margin-top: -1.25%;
        }
  
        table {
          tr {
            display: flex;
          }
        }
      }
  
      .layout-fixed {
        table-layout: fixed;
      }
    }
    }

.customize-btn {
  position: absolute;
  right: 40px;
  top: 15px;
  cursor: pointer;
  @include font-size(14);

  .custom-icon {
    @include margin-padding(right, #{pxToRem(8)}, all, null);
  }
}

.actionLogGrid-modal {
  .modal-dialog {
    max-width: 85%;
    top: 10%;

    .modal-content {
      padding: 0.5rem;
    }

    .siteName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.tooltip-content {
  font-family: $font-family-base;
  @include rhythm(11, 13);
  padding: #{pxToRem(2)} #{pxToRem(4)};
  margin-bottom: 0;
  width: #{pxToRem(200)};
}

.table-legend {
  background-color: $primary-white;
  text-align: center;

  span {
    background-color: $primary-white;
    padding: 3px 10px 0px 10px;
  }
}

span.column-title {
  display: flex;
  gap: 10px;
}